<template>
  <div>
    <div class="about">
      <div class="content">
        <div class="container">
          <h1>Who I am</h1>
          <p>
            My name is Konstantin Rusanov. I’m a web developer based in Tomsk,
            Russia.
            <br />
            <br />
            I develop and design web applications and websites.During the day I
            spend working on client’s projects remotely.
          </p>
        </div>
      </div>
      <div class="tools">
        <h2>Tools I use</h2>
        <div class="container">
          <div class="icons">
            <img
              src="../assets/images/about/icons/javascript.png"
              alt="javascript"
            />
            <img src="../assets/images/about/icons/vue.png" alt="vue" />
            <img src="../assets/images/about/icons/figma.png" alt="figma" />
            <img src="../assets/images/about/icons/git.png" alt="git" />
            <img
              src="../assets/images/about/icons/webstorm.png"
              alt="webstorm"
            />
            <img src="../assets/images/about/icons/netlify.png" alt="netlify" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {}
};
</script>

<style scoped>
.container {
  max-width: 90%;
}

.content {
  background-color: var(--light);
}

.container h1 {
  color: var(--dark);
  font-size: 2rem;
  padding-top: 1em;
}

.container p {
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2em;
}

.tools h2 {
  font-size: 2rem;
  color: var(--light);
  margin-top: 1.5em;
}

.icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-gap: 1em;
  margin: 4em auto;
  justify-content: center;
  justify-items: center;
  width: 100%;
}

.icons img {
  object-fit: cover;
  height: 70px;
}

/*MEDIA QUERIES*/
@media screen and (max-width: 900px) {
  .icons {
    grid-gap: 2em;
    padding: 1em;
  }

  .icons img:last-child {
    grid-column: 2/4;
  }
}

@media screen and (max-width: 768px) {
  .icons {
    margin-top: 2em;
  }

  .icons img:last-child {
    grid-column: 2/3;
  }
}

@media screen and (max-width: 600px) {
  .icons img:last-child {
    grid-column: 1/2;
  }
}

@media screen and (max-width: 480px) {
  .icons img {
    height: 50px;
  }
}

@media screen and (max-width: 393px) {
  p {
    font-size: 1.2rem !important;
  }

  .icons {
    grid-template-columns: 1fr 1fr;
  }

  .icons img {
    height: 40px;
  }
}

@media screen and (max-width: 320px) {
  h1,
  h2 {
    font-size: 1.5rem !important;
  }

  .icons img:last-child {
    grid-column: 2/2;
  }
}
</style>
