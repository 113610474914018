<template>
  <div class="payment" v-if="hasPassword">
    <div class="payment__item" v-for="item in items" :key="item.title">
      <img :src="item.image" alt="" class="payment__image" />

      <h2 class="payment__title">{{ item.title }}</h2>

      <div class="payment__date">
        Когда оплатить
        <span>{{ item.rechargeDate }} </span>
      </div>

      <button class="payment__button" @click="onPay(item.link)">
        оплатить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",

  data() {
    return {
      hasPassword: false,
      items: [
        {
          title: "Beeline Uzbekistan",
          image: require("../assets/images/payment/main-logo.png"),
          link: "https://beeline.uz/ru/dashboard",
          rechargeDate: "29 числа"
        },
        {
          title: "Ростелеком",
          image: require("../assets/images/payment/rostelecom.jpg"),
          link: "https://start.rt.ru/?tab=main",
          rechargeDate: "29 числа"
        },
        {
          title: "Томскэнергосбыт",
          image: require("../assets/images/payment/energosbyt.png"),
          link:
            "https://my.tomskenergosbyt.ru/accounts/156069/events/payment-doc",
          rechargeDate: "20 числа"
        },
        {
          title: "Капремонт",
          image: require("../assets/images/payment/kapremont.png"),
          link: "https://kapremont.tomsk.ru/pay/",
          rechargeDate: "20 числа"
        },
        {
          title: "Томский расчетный центр",
          image: require("../assets/images/payment/voda.png"),
          link:
            "https://tomrc.ru/payment/?barcode=200005205167&name=%D0%A0%D1%83%D1%81%D0%B0%D0%BD%D0%BE%D0%B2%20%D0%90%D0%BB%D0%B5%D0%BA%D1%81%D0%B0%D0%BD%D0%B4%D1%80%20%D0%92%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%B5%D0%B2%D0%B8%D1%87",
          rechargeDate: "20 числа"
        }
      ]
    };
  },

  methods: {
    checkPassword() {
      const result = localStorage.getItem("pass");
      if (result) {
        this.hasPassword = true;
        return;
      }

      const promptPass = prompt("PASSWORD");
      if (promptPass === process.env.VUE_APP_PASSWORD) {
        localStorage.setItem("pass", process.env.VUE_APP_PASSWORD);
        this.hasPassword = true;
      }
    },

    onPay(link) {
      window.open(link, "_blank");
    }
  },

  mounted() {
    this.checkPassword();
  }
};
</script>

<style scoped>
.payment {
  max-width: 80%;
  margin: 50px auto 0 auto;
}

.payment__item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary);
  padding-bottom: 4px;
  color: var(--secondary);
  margin-bottom: 10px;
}

.payment__image {
  height: 40px;
  width: 200px;
  object-fit: contain;
  object-position: center left;
}

.payment__title {
  margin-left: 200px;
  margin-right: auto;
}

.payment__date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: flex-end;
  margin-right: 300px;
  margin-left: auto;
}

.payment__date span {
  color: var(--accent);
}

.payment__button {
  font-size: 32px;
  background: var(--accent-secondary);
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 4px 20px;
  color: var(--dark);
}
</style>
