<template>
  <div class="home">
    <div class="container">
      <div class="image">
        <img src="../assets/images/main image.png" alt="main-image" />
      </div>
      <div class="info">
        <h2>Hey, I’m Konstantin</h2>
        <p>
          A web developer from Tomsk in Russia. I create websites and web
          applications
        </p>
        <router-link to="/projects">My Projects</router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {}
};
</script>
<style scoped>
.container {
  display: grid;
  padding-top: 10%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 100%;
}

.image {
  width: 60%;
  margin: 0 auto;
}
.image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.info {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.info h2 {
  color: var(--light);
  font-weight: 500;
  font-size: 4rem;
  margin: 0;
}

.info p {
  color: var(--text-primary);
  font-size: 3rem;
  margin: 1em 0;
}

.info a {
  font-size: 2rem;
  color: var(--dark);
  padding: 0.7em 1em;
  border-radius: 50px;
  background-color: var(--accent);
  font-weight: 500;
  margin: 0;
  width: 40%;
  text-align: center;
  border: 2px solid var(--accent);
  transition: all 0.2s linear;
}

.info a:hover {
  background-color: transparent;
  border: 2px solid var(--accent);
  color: var(--light);
}

/*MEDIA QUERIES*/
@media screen and (max-width: 1200px) {
  .container {
    padding-top: 15%;
  }

  .image {
    width: 80%;
  }

  .info h2 {
    font-size: 2.5rem;
  }

  .info p {
    font-size: 2rem;
  }

  .info a {
    width: 50%;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding-top: 23%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    grid-column-gap: 2em;
  }
  .image {
    width: 100%;
  }

  .info a {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .info a {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .container {
    grid-template-columns: 1fr;
    padding-top: 10%;
  }

  .image {
    width: 70%;
  }

  .info h2 {
    font-size: 2rem;
    margin-top: 1em;
  }
}

@media screen and (max-width: 393px) {
  .info {
    justify-self: center;
  }

  .info h2 {
    text-align: center;
  }

  .info p {
    padding-left: 25px;
  }

  .info a {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 393px) {
  .image {
    width: 100%;
  }
}
</style>
