<template>
  <div class="contact">
    <div class="container">
      <div class="mail">
        <h1>Contact Me</h1>
        <div class="mail-address">
          <img src="../assets/images/about/icons/mail.png" alt="mail" />
          <span>kentforth@mail.ru</span>
        </div>
      </div>
      <!--      <p>
        If you have any ideas about your project and want me to make it happen,
        please send me a message and I will reply you within 1-2 days
      </p>
      <form class="form" id="message-form" v-on:submit.prevent="sendMessage">
        <div class="form-input">
          <label for="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            v-model.trim="$v.name.$model"
            :class="$v.name.$error ? 'is-invalid' : ''"
          />
          <div
            class="invalid-feedback"
            v-if="$v.name.$dirty && !$v.name.required"
          >
            Name is required
          </div>
        </div>
        <div class="form-input">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            :class="$v.email.$error ? 'is-invalid' : ''"
            v-model.trim="$v.email.$model"
          />
          <div
            class="invalid-feedback"
            v-if="$v.email.$dirty && !$v.email.required"
          >
            Email is required
          </div>
          <div
            class="invalid-feedback"
            v-if="$v.email.$dirty && !$v.email.email"
          >
            Please, type a valid email address
          </div>
        </div>
        <div class="form-input">
          <label for="message">Message</label>
          <textarea
            name="message"
            id="message"
            cols="50"
            rows="7"
            :class="$v.message.$error ? 'is-invalid' : ''"
            v-model.trim="$v.message.$model"
          ></textarea>
          <div
            class="invalid-feedback"
            v-if="$v.message.$dirty && !$v.message.required"
          >
            Message is required
          </div>
        </div>
        <button type="submit" class="btn">SEND</button>
      </form>-->
    </div>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

export default {
  name: "Contact",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      subject: "Email from Portfolio Website"
    };
  },
  validations: {
    name: { required },
    email: { required, email },
    message: { required }
  },
  methods: {
    sendMessage(e) {
      this.$v.$touch();
      if (
        this.$v.name.$error ||
        this.$v.email.$error ||
        this.$v.message.$error
      ) {
        console.log("sdfsd");
      } else {
        this.sendEmail(e);
        console.log("message is sent");
      }
    },
    sendEmail: e => {
      Swal.fire({
        title: "Message has been sent",
        icon: "success",
        showConfirmButton: false,
        timer: 2500
      }).then(function() {
        window.scrollTo(0, 0);
        emailjs
          .sendForm(
            "mail_ru",
            "default",
            e.target,
            "user_Q62B7RRlfcIcFCktKXEDM"
          )
          .then(
            result => {
              console.log("SUCCESS!", result.status, result.text);
            },
            error => {
              console.log("FAILED...", error);
            }
          );
      });
    }
  }
};
</script>

<style scoped>
h1 {
  color: var(--light);
  justify-self: end;
}

.mail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mail-address {
  display: flex;
  align-items: center;
  justify-self: end;
}

.mail-address img {
  width: 30px;
}

.mail-address span {
  color: var(--secondary);
  font-size: 2rem;
  margin-left: 10px;
}

p {
  margin-top: 1em;
  font-size: 2rem;
  text-align: left;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 80%;
  grid-gap: 2em;
  justify-content: center;
  margin: 2.5em auto;
}

.form-input {
  display: flex;
  flex-direction: column;
}

label {
  color: var(--light);
  text-align: left;
  font-size: 2rem;
}

input {
  font-size: 2rem;
}

input,
textarea {
  border-radius: 7px;
  border: 4px solid var(--secondary);
  background-color: transparent;
  margin-top: 10px;
  color: var(--accent);
  padding: 10px;
}

input:focus,
textarea:focus {
  outline: none;
  border: 4px solid var(--light);
}

textarea {
  resize: none;
  font-size: 1.5rem;
}

.form-input textarea {
  width: 100%;
}

.form-input:nth-child(3) {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: start;
  width: 100%;
}

.btn {
  grid-column: 1/3;
  font-size: 2rem;
  padding: 10px 20px;
  width: 20%;
  justify-self: center;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: var(--accent);
  color: var(--dark);
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.btn:active {
  background-color: #c0b326;
}

.is-invalid {
  border: 4px solid orangered;
}

.invalid-feedback {
  color: orangered;
  text-align: left;
  padding-top: 10px;
  font-family: "Helvetica", sans-serif;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

/*MEDIA QUERIES*/
@media screen and (max-width: 768px) {
  .container {
    max-width: 90%;
  }

  input {
    width: 100%;
  }

  .form {
    max-width: 100%;
  }

  .form-input {
    width: 100%;
  }

  .mail-address span {
    font-size: 1.2rem;
  }

  .mail-address img {
    width: 18px;
  }

  .mail {
    grid-gap: 30px;
  }

  .mail h1 {
    justify-self: end;
  }

  .btn {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  .mail {
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    grid-gap: 0;
  }

  .mail h1 {
    justify-self: center;
  }

  p {
    font-size: 1.5rem;
  }

  .mail-address {
    justify-self: center;
  }

  .mail-address span {
    font-size: 1.5rem;
  }

  .mail-address img {
    width: 20px;
  }

  label {
    font-size: 1.5rem;
  }

  input {
    font-size: 1.5rem;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .btn {
    margin: 0 auto;
  }
}

@media screen and (max-width: 320px) {
  .mail h1 {
    font-size: 1.5rem;
  }

  .mail span {
    font-size: 1.2rem;
  }

  .mail-address img {
    width: 15px;
  }

  p {
    font-size: 1.2rem;
  }

  label,
  input {
    font-size: 1.3rem;
  }

  .invalid-feedback {
    font-size: 0.8rem;
  }

  .btn {
    width: 70%;
  }
}
</style>
