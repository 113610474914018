<template>
  <div id="app">
    <div id="nav" class="container-big">
      <div class="nav-logo">
        <router-link to="/">
          <h1>KR</h1>
        </router-link>
      </div>
      <div class="nav-links">
        <router-link to="/projects">Projects</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/contact">Contact</router-link>
        <button
          class="btn-download"
          type="submit"
          data-tooltip="Download Resume"
          @click.prevent="downloadFile"
        >
          <img src="./assets/images/download.svg" alt="" />
        </button>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "Konstantin Rusanov - Resume.pdf";
      link.setAttribute("download", "Konstantin Rusanov - Resume.pdf");
      link.click();
    }
  }
};
</script>
<style scoped>
.nav-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.btn-download {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: 20px;
  position: relative;
}

.btn-download::after {
  text-align: center;
  content: attr(data-tooltip);
  position: absolute;
  top: 80%;
  left: -80%;
  transform: scale(0);
  margin: 20px auto 0 auto;
  color: var(--light);
  background-color: #22315c;
  padding: 30% 50%;
  border-radius: 5px;
  font-size: 1rem;
  transition: 0.2s transform;
  transform-origin: top center;
}

.btn-download:hover::after {
  transform: scale(1);
}

@media screen and (max-width: 1024px) {
}
</style>
